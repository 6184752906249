<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <div>
          <form-wizard
            :subtitle="null"
            :title="null"
            back-button-text="Previous"
            class="mb-3"
            color="#7367F0"
            finish-button-text="Submit"
            shape="square"
            @on-complete="formSubmitted"
          >

            <!-- Basic Info tab -->
            <tab-content
              :before-change="validationForm"
              title="Basic Information"
            >
              <validation-observer
                ref="startupBasicInfoRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Basic Information
                    </h5>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Startup Name *"
                      label-for="startup"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Startup Name"
                        rules="required"
                      >
                        <b-form-input
                          id="startup"
                          v-model="startup.title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Startup Name"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Designation *"
                      label-for="designation"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Designation"
                        rules="required"
                      >
                        <b-form-input
                          id="designation"
                          v-model="startup.designation"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Your Designation"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Description"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="startup.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Startup Description"
                          rows="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                  >
                    <!-- Styled -->
                    <b-form-group
                      description="Supported formats: jpeg, png, gif"
                      label-for="startupLogo"
                      label="Upload Startup Logo (Max 2MB)"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="File"
                        rules="size:2000"
                      >
                        <b-form-file
                          id="startupLogo"
                          v-model="file"
                          :state="errors.length > 0 ? false:null"
                          accept="image/jpeg, image/png, image/gif"
                          drop-placeholder="Drop file here..."
                          placeholder="Choose a file or drop it here..."
                          style="margin-bottom: 0.5rem;"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Registered Name"
                      label-for="registered_title"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Registered Name"
                      >
                        <b-form-input
                          id="registered_title"
                          v-model="startup.registered_title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Startup Registered Name"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="CIN"
                      label-for="cin"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="CIN"
                      >
                        <b-form-input
                          id="cin"
                          v-model="startup.cin"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Startup Registration Number"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="DPIIT"
                      label-for="dpiit"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="DPIIT"
                      >
                        <b-form-input
                          id="dpiit"
                          v-model="startup.dpiit"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Startup DPIIT Number"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Official Email *"
                      label-for="official_email"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Official Email"
                        rules="required"
                      >
                        <b-form-input
                          id="official_email"
                          v-model="startup.official_email"
                          :state="errors.length > 0 ? false:null"
                          placeholder="hello@yourbusiness.com"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Phone Number *"
                      label-for="url"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Phone Number"
                        rules="required"
                      >
                        <b-form-input
                          id="official_phone"
                          v-model="startup.official_phone"
                          :state="errors.length > 0 ? false:null"
                          placeholder="+91-1234567890"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Website"
                      label-for="url"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Website"
                      >
                        <b-form-input
                          id="url"
                          v-model="startup.url"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Startup Website URL"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Industry"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Industry *"
                        label-for="industry"
                      >
                        <v-select
                          id="industry"
                          v-model="industryArray"
                          :options="industries"
                          multiple
                          placeholder="Select Industry"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Lifecycle Stage"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Lifecycle Stage *"
                        label-for="startup_stage"
                      >
                        <v-select
                          id="startup_stage"
                          v-model="startup.startup_stage"
                          :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                          placeholder="Select Lifecycle Stage"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="City *"
                      label-for="city"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="City"
                        rules="required"
                      >
                        <b-form-input
                          id="city"
                          v-model="startup.city"
                          :state="errors.length > 0 ? false:null"
                          placeholder="City"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="State"
                      label-for="state"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="State"
                      >
                        <b-form-input
                          id="state"
                          v-model="startup.state"
                          :state="errors.length > 0 ? false:null"
                          placeholder="State"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Country *"
                      label-for="country"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Country"
                        rules="required"
                      >
                        <b-form-input
                          id="country"
                          v-model="startup.country"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Country"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Founders' Details tab -->
            <tab-content
              title="Founders' Details"
            >
              <validation-observer
                ref="startupFounderDetailsRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Founders' Details
                    </h5>
                  </b-col>
                  <b-col md="12">
                    <founders-info
                      ref="foundersInfo"
                      v-model="startup.startups_founderstables"
                      :founders="startup.startups_founderstables"
                    />
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

          </form-wizard>

        </div>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BFormFile, BFormGroup, BFormInput, BFormTextarea, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import FoundersInfo from '@/views/startup-portal/components/FoundersInfo.vue'
import { mapGetters } from 'vuex'
import industryList from '@/constants/industry-list'

export default {
  components: {
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    FoundersInfo,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      file: null,
      mutationLoading: false,
      startup: {
        title: null,
        designation: null,
        description: null,
        registered_title: null,
        cin: null,
        dpiit: null,
        logo: null,
        official_email: null,
        official_phone: null,
        url: null,
        industry: '',
        startup_stage: '',
        city: null,
        state: null,
        country: null,
        startups_founderstables: [],
      },
      industries: industryList,
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      userDetails: 'user/getUserDetails',
    }),
    industryArray: {
      get() {
        // Convert the comma-separated string to an array
        return this.startup.industry ? this.startup.industry?.split(',') : []
      },
      set(value) {
        this.startup.industry = value.join(',')
      },
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async handleFileUpload() {
      try {
        return await this.uploadFile(this.file)
      } catch (error) {
        this.showAlert('Error uploading file!', 'Error')
      } finally {
        this.mutationLoading = false
      }
    },
    formSubmitted() {
      if (this.startup.startups_founderstables.length === 0 || !this.$refs.foundersInfo.validationForm()) {
        this.showAlert('Please enter founders\' required details', 'warning')
        return
      }
      this.addStartup()
      this.mutationLoading = true
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.startupBasicInfoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    mutationObject() {
      if (this.file) {
        this.handleFileUpload()
          .then(data => {
            this.startup.logo = JSON.stringify(data)
          })
      }
      const founders = this.startup.startups_founderstables.filter(founder => founder.name)
        .map(founder => founder)
      const startupDetails = { ...this.startup }
      delete startupDetails.assoc_id
      delete startupDetails.designation
      delete startupDetails.startups_founderstables
      const object = {
        users_organizationtable: {
          data: {
            startups_founderstables: {
              data: founders,
              on_conflict: {
                constraint: 'startups_founderstable_pkey',
                update_columns: ['name',
                  'din_number',
                  'designation',
                  'email',
                  'phone',
                  'equity_holding'],
              },
            },
            ...startupDetails,
          },
          on_conflict: {
            constraint: 'users_organizationtable_pkey',
            update_columns: ['title',
              'description',
              'registered_title',
              'cin',
              'dpiit',
              'official_email',
              'official_phone',
              'url',
              'logo',
              'industry',
              'startup_stage',
              'city',
              'state',
              'country'],
          },
        },
        designation: this.startup.designation,
        role: 'startup',
        user_id: this.userDetails.id,
      }
      if (this.startup.assoc_id) object.id = this.startup.assoc_id
      delete object.assoc_id
      return object
    },
    addStartup() {
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation update_startup_details($object: users_associationtable_insert_input! ) {
          delete_startups_founderstable(where: {organization_id: {_eq: ${this.startup.id || 0} }}) {
              affected_rows
          }
          insert_users_associationtable_one(on_conflict: {update_columns: designation, constraint: users_associationtable_pkey}, object: $object) {
            # TODO: Use a fragment to have same details as initial query after login.
            id
            organization_id
            incubator_id
            role
            designation
            users_organizationtable {
                id
                title
                type
                domain
            }
            usersOrganizationtableByIncubator {
                id
                title
                type
                domain
            }
          }
        }`,
        variables: {
          object: this.mutationObject(),
        },
        update: (store, { data: { insert_users_associationtable_one } }) => {
          this.mutationLoading = false

          if (!insert_users_associationtable_one.id) this.showAlert('Failed to update startup', 'danger')
          else if (this.startup.id) this.showAlert('Startup updated successfully', 'success')
          else this.showAlert('Startup added successfully', 'success')

          // eslint-disable-next-line camelcase
          this.$store.dispatch('user/addUserAssociatedOrg', insert_users_associationtable_one)

          this.$router.push({ name: 'startup-manager' })
        },
      })
      // this.resetValues()
      return this.error
    },
  },
  apollo: {
    startup: {
      query() {
        return gql`
          query getStartupDetailsByAssocId($id: Int!) {
            users_associationtable_by_pk(id: $id) {
              id
              designation
              users_organizationtable {
                id
                title
                description
                logo
                registered_title
                cin
                dpiit
                official_email
                official_phone
                url
                industry
                startup_stage
                city
                state
                country
                startups_founderstables {
                  id
                  name
                  din_number
                  designation
                  email
                  phone
                  equity_holding
                }
              }
            }
          }`
      },
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      update: data => ({
        assoc_id: data.users_associationtable_by_pk.id,
        designation: data.users_associationtable_by_pk.designation,
        ...data.users_associationtable_by_pk.users_organizationtable,
      }),
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
